<mat-toolbar class="navbar"
             [ngClass]="bpPantalla"
             *ngIf="vinculos.length > 0"
             color="primary">
  <button class="menu"
          (click)="abreMenu()"
          mat-icon-button
          aria-label="Menú principal">
    <mat-icon>menu</mat-icon>
  </button>
  <img class="logo"
       [src]="rutaLogos + 'logo_sobre_verde.svg'"
       alt="Logotipo de Le Tiende">
  <div class="enlaces">
    @for (vinculo of vinculos; track vinculo){
    <a mat-button
       class="enlace"
       *ngIf="!enDesarrollo.includes(vinculo.vinculo)"
       routerLinkActive="activo"
       [routerLink]="vinculo.vinculo">
      {{ vinculo.lang[idioma].toUpperCase() }}
    </a>
    }
  </div>
  @if (vinculos[numVinculo] && !enDesarrollo.includes(vinculos[numVinculo].vinculo)){
  <div class="titulo">
    {{ vinculos[numVinculo].lang[idioma] }}
  </div>
  }
  <span class="spacer"></span>
  <div class="idioma">
    <button mat-icon-button
            aria-label="Menú idioma"
            [matMenuTriggerFor]="menuIdioma">
      <mat-icon [svgIcon]="bandera"></mat-icon>
    </button>
  </div>
</mat-toolbar>
<mat-menu #menuIdioma="matMenu">
  <button mat-menu-item
          aria-label="Español de Colombia"
          (click)="cambiaIdioma('es')">
    <mat-icon svgIcon="flag-co"></mat-icon>
    <span>Español</span>
  </button>
  <button mat-menu-item
          aria-label="UK English"
          (click)="cambiaIdioma('en')">
    <mat-icon svgIcon="flag-gb"></mat-icon>
    <span>English</span>
  </button>
</mat-menu>