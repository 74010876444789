<div class="pie">
  <div class="sociales">
    <a href="https://www.instagram.com/letiende_parkway/"
       aria-label="IG de Le Tiende"
       target="_blank"
       mat-icon-button>
      <fa-icon [icon]="ig"
               size="2x">
      </fa-icon>
    </a>
    <a href="https://www.tiktok.com/@letiende_parkway"
       aria-label="TikTok de Le Tiende"
       target="_blank"
       mat-icon-button>
      <fa-icon [icon]="tiktok"
               size="2x">
      </fa-icon>
    </a>
  </div>
  <div class="direccion">Carrera 24 #37-44, Bogotá</div>
</div>