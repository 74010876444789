export const environment = {
  firebase: {
    projectId: 'le-tiende-2791f',
    appId: '1:606861045627:web:37797f4d646f5a72b717de',
    storageBucket: 'le-tiende-2791f.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyD--GnW32STU8GghrAhVgDXQQMHe0wfypo',
    authDomain: 'le-tiende-2791f.firebaseapp.com',
    messagingSenderId: '606861045627',
    measurementId: 'G-TVXJHNZZEQ',
  },
  production: true,
  googleAnalytics: 'G-TQTGGTM26R',
  enDesarrollo: ['inicio', 'libros', 'auditorio']
};
