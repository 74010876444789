<mat-card class="ficha-disco mat-elevation-z4"
          *ngIf="disco">
  <button mat-icon-button
          class="cerrar"
          aria-label="Cerrar"
          (click)="esteDialogo.close()"
          [class.visible]="modal">
    <mat-icon>close</mat-icon>
  </button>
  <img mat-card-image
       *ngIf="disco.cover"
       [src]="disco.cover"
       [alt]="disco.album + ' - ' + disco.artista">
  <mat-card-content class="contenido"
                    [class.visible]="contenidoVisible">
    <div class="album">
      <fa-icon [icon]="album"
               class="icono"
               [fixedWidth]="true">
      </fa-icon>
      <span class="texto">{{ disco.album }}</span>
    </div>
    <mat-divider></mat-divider>
    <div class="artista">
      <fa-icon [icon]="artista"
               class="icono"
               [fixedWidth]="true">
      </fa-icon>
      <span class="texto">{{ disco.artista }}</span>
    </div>
    <mat-divider></mat-divider>
    <div class="estado">
      <fa-icon [icon]="estado"
               class="icono"
               [fixedWidth]="true">
      </fa-icon>
      <span class="texto">{{ disco.estado }}</span>
    </div>
    <mat-divider></mat-divider>
    <div class="valor">
      <fa-icon [icon]="valor"
               class="icono"
               [fixedWidth]="true">
      </fa-icon>
      <span class="texto">{{ disco.valor | currency:'COP':'symbol-narrow':'1.0-0' }}</span>
    </div>
    <mat-divider></mat-divider>
    <ng-container *ngIf="disco.origen && disco.barcode.toString().substring(0, 3) != 'LTD'">
      <div class="origen">
        <fa-icon [icon]="origen"
                 class="icono"
                 [fixedWidth]="true">
        </fa-icon>
        <span class="texto">{{ disco.origen }}</span>
      </div>
      <mat-divider></mat-divider>
    </ng-container>
    <ng-container *ngIf="disco.anno">
      <div class="anno">
        <fa-icon [icon]="anno"
                 class="icono"
                 [fixedWidth]="true">
        </fa-icon>
        <span class="texto">{{ disco.anno }}</span>
      </div>
      <mat-divider></mat-divider>
    </ng-container>
    <ng-container *ngIf="disco.genero?.length">
      <div class="genero">
        <fa-icon [icon]="genero"
                 class="icono"
                 [fixedWidth]="true">
        </fa-icon>
        <mat-chip-listbox class="chips-discos">
          <mat-chip-option *ngFor="let genero of disco.genero">
            {{ genero }}
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
      <mat-divider></mat-divider>
    </ng-container>
    <div class="descripcion">
      <fa-icon [icon]="descripcion"
               class="icono"
               [fixedWidth]="true">
      </fa-icon>
      <mat-chip-listbox class="chips-discos">
        <mat-chip-option *ngFor="let descripcion of disco.descripcion?.split(', ')">
          {{ descripcion }}
        </mat-chip-option>
      </mat-chip-listbox>
    </div>
  </mat-card-content>
</mat-card>