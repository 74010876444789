<div class="ficha-evento">
  <mat-card class="evento mat-elevation-z4">
    <img mat-card-image
         [alt]="evento.nombre"
         [src]="portadaDefecto()">
    <mat-card-content>
      <div class="cerrar"
           *ngIf="_evento">
        <button mat-icon-button
                aria-label="Cerrar"
                (click)="cierraDialogo()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="fecha">
        <div class="mes">{{ evento.fecha | date: 'MMM':'GMT-5:00':interfaz.fecha[idioma] }}</div>
        <div class="dia">{{ evento.fecha | date: 'd':'GMT-5:00':interfaz.fecha[idioma] }}</div>
        <div class="hora">{{ evento.fecha | date: 'h:mm a':'GMT-5:00':interfaz.fecha[idioma] }}</div>
      </div>
      <div class="info">
        <div class="texto">
          <div class="titulo">{{ evento.nombre }}</div>
          <div class="descripcion">{{ evento.descripcion }}</div>
        </div>
        <div class="acciones"
             *ngIf="evento.ig || evento.tiktok || evento.link || evento.registro">
          <a mat-icon-button
             *ngIf="evento.link"
             [matTooltip]="interfaz.masinformacion[idioma]"
             matTooltipPosition="above"
             [href]="evento.link"
             [target]="evento.link.indexOf('letiende.co') > -1 ? '_self' : '_blank'"
             aria-label="Más información sobre este evento">
            <fa-icon [icon]="info"></fa-icon>
          </a>
          <a mat-icon-button
             *ngIf="evento.ig"
             matTooltip="Instagram"
             matTooltipPosition="above"
             [href]="evento.ig"
             target="_blank"
             aria-label="Consulta el IG de este evento">
            <fa-icon [icon]="ig"></fa-icon>
          </a>
          <a mat-icon-button
             *ngIf="evento.tiktok"
             matTooltip="TikTok"
             matTooltipPosition="above"
             [href]="evento.tiktok"
             target="_blank"
             aria-label="Consulta el TikTok de este evento">
            <fa-icon [icon]="tiktok"></fa-icon>
          </a>
          <a mat-icon-button
             *ngIf="evento.registro"
             [matTooltip]="interfaz.registro[idioma]"
             matTooltipPosition="above"
             [href]="evento.registro"
             target="_blank"
             aria-label="Regístrate para participar en este evento">
            <fa-icon [icon]="registro"></fa-icon>
          </a>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>