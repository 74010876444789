@if (interfaz) {
<div class="auditorio"
     [ngClass]="bpPantalla">
  <button mat-fab
          aria-label="Cotizar el auditorio"
          [matTooltip]="interfaz.cotizar[idioma]"
          class="cotizar">
    <mat-icon>request_quote</mat-icon>
  </button>
  @if (!openDrawer) {
  <div class="despliega"
       @abreSubnav>
    <button mat-icon-button
            aria-label="Ver menú"
            (click)="openDrawer=true">
      <mat-icon>menu_open</mat-icon>
    </button>
  </div>
  }
  <mat-sidenav-container (backdropClick)="openDrawer = false"
                         #sidenavContainer>
    <mat-sidenav [mode]="modoDrawer"
                 [fixedInViewport]="bpPantalla == 'sm' || bpPantalla == 'xs'"
                 [opened]="openDrawer">
      <mat-nav-list>
        @for (subVinculo of subVinculos; track subVinculo; let num = $index) {
        <a [routerLink]="['..', subVinculo.link]"
           mat-list-item
           (click)="abreSubVinculo(num)"
           [activated]="subMenuActivo(subVinculo.link)">
          <mat-icon matListItemIcon>checklist_rtl</mat-icon>
          {{subVinculo.titulo}}
          <mat-icon class="active">arrow_right</mat-icon>
        </a>
        }
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      @for (subVinculo of subVinculos; track subVinculo; let num = $index) {
      <div class="sidenav-content"
           [id]="'auditorio-' + subVinculo.link">
        <h2>{{subVinculo.titulo}}</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.</p>
      </div>
      }
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
}