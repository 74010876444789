<div class="eventos"
     *ngIf="interfaz">
  <div class="header">
    <div class="selector-vista">
      <span>
        {{ interfaz.vistade[idioma] }}
      </span>
      <mat-button-toggle-group [(ngModel)]="modoVista">
        <mat-button-toggle value="eventos">
          {{ interfaz.tipoeventos[idioma] }}
        </mat-button-toggle>
        <mat-button-toggle value="calendario">
          {{ interfaz.tipocalendario[idioma] }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="calendario"
       *ngIf="modoVista == 'calendario'">
    <nav class="navegacion">
      <div class="atras">
        <button mat-icon-button
                aria-label="Mes anterior"
                (click)="calculaCalendario(-1)">
          <mat-icon>chevron_left</mat-icon>
        </button>
      </div>
      <div class="fecha"
           *ngIf="interfaz">
        {{ (mes | date: 'MMMM y':'GMT-5:00':interfaz.fecha[idioma])?.toUpperCase() }}
      </div>
      <div class="adelante">
        <button mat-icon-button
                aria-label="Mes siguiente"
                (click)="calculaCalendario(1)">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
    </nav>
    <div class="almanaque mat-elevation-z4">
      <mat-grid-list cols="7"
                     class="almanaque-titulo"
                     rowHeight="2em">
        <mat-grid-tile *ngFor="let nomDia of semanas[0]"
                       class="dia">
          {{ (nomDia | date: 'E':'GMT-5:00':interfaz.fecha[idioma])?.toUpperCase() }}
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list cols="7"
                     class="almanaque-dias"
                     rowHeight="96px">
        <ng-container *ngFor="let semana of semanas">
          <mat-grid-tile *ngFor="let dia of semana"
                         class="dia"
                         [ngClass]="{'actual': dia.getMonth() == mes.getMonth(), 'pasado': dia < hoy && !esHoy(dia)}">
            <div class="num-dia"
                 [ngClass]="{'hoy': esHoy(dia)}">
              {{ dia | date: 'd':'GMT-5:00':interfaz.fecha[idioma]}}
            </div>
            <ng-container *ngFor="let evento of eventosDia(dia)">
              <div class="evento"
                   (click)="abreFichaEvento(evento)">
                <span>
                  {{ evento.nombre }}
                </span>
                <fa-icon [icon]="expande"
                         class="expande">
                </fa-icon>
              </div>
            </ng-container>
          </mat-grid-tile>
        </ng-container>
      </mat-grid-list>
    </div>
  </div>
  <div class="eventos-animados"
       *ngIf="modoVista == 'eventos'">
    <div class="lista-eventos"
         *ngIf="eventos.length > 0"
         @eventos>
      <ng-container *ngFor="let evento of eventos">
        <lt-ficha-evento [evento]="evento"
                         *ngIf="evento.fecha && evento.fecha > hoy">
        </lt-ficha-evento>
        <!-- 
        <mat-card class="evento mat-elevation-z4"
                  *ngIf="evento.fecha && evento.fecha > hoy">
          <img mat-card-image
          [alt]="evento.nombre"
               [src]="evento.portada">
          <mat-card-content>
            <div class="fecha">
              <div class="mes">{{ evento.fecha | date: 'MMM':'GMT-5:00':interfaz.fecha[idioma] }}</div>
              <div class="dia">{{ evento.fecha | date: 'd':'GMT-5:00':interfaz.fecha[idioma] }}</div>
              <div class="hora">{{ evento.fecha | date: 'h:mm a':'GMT-5:00':interfaz.fecha[idioma] }}</div>
            </div>
            <div class="info">
              <div class="texto">
                <div class="titulo">{{ evento.nombre }}</div>
                <div class="descripcion">{{ evento.descripcion }}</div>
              </div>
              <div class="acciones"
                   *ngIf="evento.ig || evento.tiktok || evento.link || evento.registro">
                <a mat-icon-button
                   [matTooltip]="interfaz.masinformacion[idioma]"
                   matTooltipPosition="above"
                   [href]="evento.link"
                   [target]="evento.link.indexOf('letiende.co') > -1 ? '_self' : '_blank'"
                   *ngIf="evento.link">
                  <fa-icon [icon]="info"></fa-icon>
                </a>
                <a mat-icon-button
                   matTooltip="Instagram"
                   matTooltipPosition="above"
                   [href]="evento.ig"
                   target="_blank"
                   *ngIf="evento.ig">
                  <fa-icon [icon]="ig"></fa-icon>
                </a>
                <a mat-icon-button
                   matTooltip="TikTok"
                   matTooltipPosition="above"
                   [href]="evento.tiktok"
                   target="_blank"
                   *ngIf="evento.tiktok">
                  <fa-icon [icon]="tiktok"></fa-icon>
                </a>
                <a mat-icon-button
                   [matTooltip]="interfaz.registro[idioma]"
                   matTooltipPosition="above"
                   [href]="evento.registro"
                   target="_blank"
                   *ngIf="evento.registro">
                  <fa-icon [icon]="registro"></fa-icon>
                </a>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
         -->
      </ng-container>
      <mat-card class="evento vacio mat-elevation-z4"
                *ngIf="!hayEventos()">
        <div class="icono">
          <fa-icon [icon]="doh"
                   size="lg">
          </fa-icon>
        </div>
        <div class="texto">
          <div class="ups">{{ interfaz.ups[idioma] }}</div>
          <div class="titulo">{{ interfaz.noeventos[idioma] }}</div>
          <div class="explicacion"
               [innerHTML]="textoSiguenos() | safe:'html'">
            <!--
            Síguenos en
            <a class="vinculo"
               target="_blank"
               href="https://www.instagram.com/letiende_parkway/">
              IG
            </a>
            o
            <a href="https://www.letiende.co/registro"
               target="_blank"
               class="vinculo">
              regístrate
            </a>
            para que te avisemos de nuestros próximos eventos.
          -->
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>