<div class="menu"
     [ngClass]="bpPantalla">
  <ng-container *ngFor="let categoria of menu">
    <div class="categoria"
         [ngClass]="simplifica(categoria.titulo[idioma])"
         [ngStyle]="{'backgroundImage': imagenFondo(categoria.imagen)}"
         *ngIf="categoria.visible">
      <div class="texto">
        <div class="titulo">
          {{ categoria.titulo[idioma] }}
        </div>
        <div *ngIf="categoria.descripcion[idioma]"
             class="descripcion">
          {{ categoria.descripcion[idioma] }}
        </div>
      </div>
      <div class="logo"></div>
      <div class="subcategorias"
           @subcategorias>
        <ng-container *ngFor="let subcategoria of categoria.hijos">
          <div class="subcategoria"
               *ngIf="subcategoria.visible && subcategoria.titulo.es != 'Promociones'">
            <div class="texto">
              <div class="titulo">{{subcategoria.titulo[idioma]}}</div>
              <div *ngIf="subcategoria.descripcion[idioma]"
                   class="descripcion">
                {{subcategoria.descripcion[idioma]}}
              </div>
            </div>
            <ng-container *ngFor="let producto of subcategoria.hijos">
              <div class="producto"
                   *ngIf="producto.visible">
                <div class="imagen"
                     *ngIf="imagenProducto(producto.imagen).tipo != 'vacio'">
                  <fa-icon *ngIf="imagenProducto(producto.imagen).icono"
                           animation="beat-fade"
                           [fixedWidth]="true"
                           size="xs"
                           [icon]="imagenProducto(producto.imagen).icono">
                  </fa-icon>
                  <img *ngIf="imagenProducto(producto.imagen).tipo == 'imagen'"
                       [alt]="producto.titulo[idioma]"
                       [src]="imagenProducto(producto.imagen).ruta">
                </div>
                <div class="texto">
                  <div class="titulo">
                    {{producto.titulo[idioma]}}
                  </div>
                  <div *ngIf="producto.descripcion[idioma]"
                       class="descripcion">
                    {{producto.descripcion[idioma]}}
                  </div>
                </div>
                <div class="spacer"></div>
                <div class="valor">
                  {{producto.valor | currency:'COP':'symbol-narrow':'1.0-0'}}
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
  <div class="promociones">
    <button class="boton-promo"
            matTooltip="Promos"
            matTooltipPosition="left"
            [matMenuTriggerFor]="promos"
            aria-label="Abrir promociones"
            mat-fab>
      <fa-icon [icon]="promo"
               size="lg"></fa-icon>
    </button>
  </div>
</div>
<mat-menu class="promo"
          backdropClass="backPromo"
          xPosition="before"
          yPosition="below"
          #promos="matMenu">
  <button class="cerrar"
          aria-label="Cerrar"
          mat-icon-button>
    <mat-icon>close</mat-icon>
  </button>
  <ng-container *ngFor="let cat of menu">
    <ng-container *ngFor="let subcat of cat.hijos">
      <div class="subcat"
           *ngIf="subcat.titulo.es == 'Promociones'">
        <div class="promo-titulo">
          <span>{{ subcat.titulo[idioma] }}</span>
        </div>
        <ng-container *ngFor="let producto of subcat.hijos">
          <div class="producto"
               *ngIf="producto.visible">
            <div class="imagen"
                 *ngIf="imagenProducto(producto.imagen).tipo != 'vacio'">
              <fa-icon *ngIf="imagenProducto(producto.imagen).icono"
                       animation="beat-fade"
                       [fixedWidth]="true"
                       size="xs"
                       [icon]="imagenProducto(producto.imagen).icono">
              </fa-icon>
              <img *ngIf="imagenProducto(producto.imagen).tipo == 'imagen'"
                   [alt]="producto.titulo[idioma]"
                   [src]="imagenProducto(producto.imagen).ruta">
            </div>
            <div class="texto">
              <div class="titulo">{{producto.titulo[idioma]}}</div>
              <div class="descripcion">{{producto.descripcion[idioma]}}</div>
            </div>
            <span class="spacer"></span>
            <div class="valor">{{producto.valor | currency:'COP':'symbol-narrow':'1.0-0'}}</div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</mat-menu>